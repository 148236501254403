<template>
  <Disclosure as="nav" class="bg-white shadow-md" v-slot="{ open }">
    <div class="max-w-10xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="flex-shrink-0 flex items-center">
            <router-link
              to="/"
              class="flex-shrink-0 flex items-center w-full h-full"
            >
              <!-- AI Campus Logo -->
              <div>
                <img
                  class="block h-16 my-auto mr-4"
                  src="../assets/ai-campus_long.png"
                  alt=""
                />
              </div>
            </router-link>
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
            <div class="m-auto" v-for="route in routes" :key="route.path">
              <router-link
                v-if="!route.routes"
                active-class="border-indigo-500 text-gray-900 hover:border-indigo-500"
                :to="route.path"
                class="
                  border-transparent
                  text-gray-500
                  hover:border-gray-300 hover:text-gray-700
                  inline-flex
                  items-center
                  px-1
                  pt-1
                  border-b-2
                  text-sm
                  font-medium
                "
              >
                {{ route.text }}
              </router-link>
              <div v-else>
                <Menu as="div" class="relative inline-block text-left">
                  <div>
                    <MenuButton
                      :class="{
                        'border-indigo-500 text-gray-900 hover:border-indigo-500':
                          selectedPath.startsWith(route.path),
                      }"
                      class="
                        border-transparent
                        text-gray-500
                        hover:border-gray-300 hover:text-gray-700
                        inline-flex
                        items-center
                        px-1
                        pt-1
                        border-b-2
                        text-sm
                        font-medium
                        focus:outline-none
                      "
                      v-slot="{ open }"
                    >
                      {{ route.text }}
                      <ChevronDownIcon
                        :class="{ 'rotate-0': open, 'rotate-90': !open }"
                        class="
                          -mr-1
                          ml-2
                          h-5
                          w-5
                          transform
                          transition-transform
                          duration-250
                          ease-in-out
                        "
                        aria-hidden="true"
                      />
                    </MenuButton>
                  </div>

                  <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      class="
                        origin-top-right
                        absolute
                        right-0
                        mt-2
                        w-56
                        rounded-md
                        shadow-lg
                        bg-white
                        ring-1 ring-black ring-opacity-5
                        focus:outline-none
                      "
                    >
                      <div class="py-1">
                        <div v-for="route in route.routes" :key="route.path">
                          <MenuItem v-slot="{ active }">
                            <router-link
                              v-if="!route.path.startsWith('http')"
                              :to="route.path"
                              :class="[
                                active
                                  ? 'border-indigo-500 hover:border-indigo-500 text-blue-600'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              ]"
                              active-class="text-blue-700"
                              >{{ route.text }}</router-link
                            >
                            <a
                              v-else
                              :href="route.path"
                              :class="[
                                active
                                  ? 'border-indigo-500 hover:border-indigo-500 text-blue-600'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              ]"
                              active-class="text-blue-700"
                              >{{ route.text }}</a
                            >
                          </MenuItem>
                        </div>
                      </div>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        <div v-if="false">
          <div class="hidden sm:ml-6 sm:flex sm:items-center">
            <!-- Profile dropdown -->

            <Menu as="div" v-if="isLoggedIn" class="ml-3 relative z-50">
              <div>
                <MenuButton
                  class="
                    bg-white
                    rounded-full
                    flex
                    text-sm
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  <span class="sr-only">Open user menu</span>
                  <img
                    class="h-8 w-8 rounded-full"
                    :src="$store.state.user.avatarSrc"
                    alt=""
                  />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    right-0
                    mt-2
                    w-48
                    rounded-md
                    shadow-lg
                    py-1
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                >
                  <MenuItem v-slot="{ active }">
                    <router-link
                      :to="{
                        name: 'Profile',
                        params: { id: $store.state.user.id },
                      }"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      >Your Profile</router-link
                    >
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <button
                      href="/logout"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm  w-full text-center',
                      ]"
                      class="
                        bg-red-500
                        hover:bg-red-600
                        text-white
                        font-semibold
                      "
                      v-on:click="logout()"
                    >
                      Sign out
                    </button>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
            <div
              v-else
              class="
                pt-3
                pb-2
                border-t border-gray-200
                text-center
                my-1
                flex
                gap-3
              "
            >
              <router-link
                to="/register"
                class="
                  mx-auto
                  w-auto
                  px-3
                  py-2
                  rounded
                  bg-gray-800
                  font-medium
                  text-white
                  uppercase
                  focus:outline-none
                  hover:bg-gray-700 hover:shadow-none
                "
              >
                Register
              </router-link>
              <router-link
                to="/login"
                class="
                  mx-auto
                  w-auto
                  px-3
                  py-2
                  rounded
                  bg-gray-800
                  font-medium
                  text-white
                  uppercase
                  focus:outline-none
                  hover:bg-gray-700 hover:shadow-none
                "
              >
                Log In
              </router-link>
            </div>
          </div>
        </div>
        <!-- Mobile Nav Menu -->
        <div class="-mr-2 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="
              inline-flex
              items-center
              justify-center
              p-2
              rounded-md
              text-gray-400
              hover:text-gray-500 hover:bg-gray-100
              focus:outline-none
              focus:ring-2
              focus:ring-inset
              focus:ring-indigo-500
            "
          >
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden" v-slot="{ close }">
      <div class="pt-2 pb-3 space-y-1">
        <div class="m-auto" v-for="route in routes" :key="route.path">
          <router-link
            v-if="
              !route.routes &&
              !route.path.startsWith('https://') &&
              !route.path.startsWith('http://')
            "
            active-class="border-indigo-500 text-gray-900 bg-indigo-50"
            :to="route.path"
            class="
              border-transparent
              text-gray-500
              hover:border-gray-300 hover:text-gray-700
              block
              pl-3
              pr-4
              py-2
              border-l-4
              text-base
              font-medium
            "
            @click="close()"
          >
            {{ route.text }}
          </router-link>
          <a
            v-else-if="
              !route.routes &&
              (route.path.startsWith('https://') ||
                route.path.startsWith('http://'))
            "
            active-class="border-indigo-500 text-gray-900 bg-indigo-50"
            :href="route.path"
            class="
              border-transparent
              text-gray-500
              hover:border-gray-300 hover:text-gray-700
              block
              pl-3
              pr-4
              py-2
              border-l-4
              text-base
              font-medium
            "
            @click="close()"
          >
            {{ route.text }}
          </a>
          <div v-else>
            <Disclosure as="div" class="relative inline-block text-left w-full">
              <div class="w-full">
                <DisclosureButton
                  :class="{
                    'border-indigo-500 text-gray-900 bg-indigo-50':
                      selectedPath.startsWith(route.path),
                  }"
                  class="
                    border-transparent
                    text-gray-500
                    hover:border-gray-300 hover:text-gray-700
                    w-full
                    pl-3
                    pr-4
                    py-2
                    border-l-4
                    text-base
                    font-medium
                    flex
                  "
                  v-slot="{ open }"
                >
                  <span>{{ route.text }}</span>
                  <ChevronDownIcon
                    :class="{ 'rotate-0': open, 'rotate-90': !open }"
                    class="
                      -mr-1
                      ml-auto
                      h-5
                      my-auto
                      w-5
                      transform
                      transition-transform
                      duration-250
                      ease-in-out
                    "
                    aria-hidden="true"
                  />
                </DisclosureButton>
              </div>
              <DisclosurePanel class="focus:outline-none">
                <div class="py-1">
                  <div v-for="route in route.routes" :key="route.path">
                    <router-link
                      v-if="
                        !route.path.startsWith('https://') &&
                        !route.path.startsWith('http://')
                      "
                      v-slot="{ active }"
                      :to="route.path"
                      :class="[
                        active
                          ? 'border-indigo-500 hover:border-indigo-500 text-blue-600'
                          : 'text-gray-700',
                        'ml-3 block px-4 py-2 text-sm',
                      ]"
                      active-class="text-blue-700"
                      @click="close()"
                      >{{ route.text }}
                    </router-link>
                    <a
                      v-else
                      :href="route.path"
                      :class="[
                        active
                          ? 'border-indigo-500 hover:border-indigo-500 text-blue-600'
                          : 'text-gray-700',
                        'ml-3 block px-4 py-2 text-sm',
                      ]"
                      active-class="text-blue-700"
                      @click="close()"
                    >
                      {{ route.text }}
                    </a>
                  </div>
                </div>
              </DisclosurePanel>
            </Disclosure>
          </div>
        </div>
      </div>
      <div v-if="false">
        <!-- Profile Info -->
        <div v-if="isLoggedIn" class="pt-4 pb-3 border-t border-gray-200">
          <div class="flex items-center px-4">
            <div class="flex-shrink-0">
              <img
                class="h-10 w-10 rounded-full"
                :src="$store.state.user.avatarSrc"
                alt=""
              />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium text-gray-800">
                {{
                  `${$store.state.user.firstName} ${$store.state.user.lastName}`
                }}
              </div>
              <div class="text-sm font-medium text-gray-500">
                {{ $store.state.user.email }}
              </div>
            </div>
          </div>
          <div class="mt-3 space-y-1">
            <router-link
              :to="{ name: 'Profile', params: { id: $store.state.user.id } }"
              class="
                block
                px-4
                py-2
                text-base
                font-medium
                text-gray-500
                hover:text-gray-800 hover:bg-gray-100
              "
              >Your Profile</router-link
            >

            <button
              href="/logout"
              :class="[
                active ? 'bg-gray-100' : '',
                'block px-4 py-2 text-sm  w-full text-center',
              ]"
              class="bg-red-500 hover:bg-red-600 text-white font-semibold"
              v-on:click="logout"
            >
              Sign out
            </button>
          </div>
          <!-- If not logged in -->
        </div>
        <div
          v-else
          class="pt-3 pb-2 border-t border-gray-200 text-center my-1 flex gap-3"
        >
          <router-link
            to="/register"
            class="
              mx-auto
              w-auto
              px-3
              py-2
              rounded
              bg-gray-800
              font-medium
              text-white
              uppercase
              focus:outline-none
              hover:bg-gray-700 hover:shadow-none
            "
          >
            Register
          </router-link>
          <router-link
            to="/login"
            class="
              mx-auto
              w-auto
              px-3
              py-2
              rounded
              bg-gray-800
              font-medium
              text-white
              uppercase
              focus:outline-none
              hover:bg-gray-700 hover:shadow-none
            "
          >
            Log In
          </router-link>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { ref } from "vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import { post } from "../helper";

export default {
  name: "Nav",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuIcon,
    XIcon,
    ChevronDownIcon,
  },
  data() {
    return {
      selectedPath: "",
      routes: [
        {
          path: "/ai-campus",
          text: "Arkansas AI Campus",
          routes: [
            {
              text: "Info",
              path: "/ai-campus/info",
            },
            {
              text: "Projects",
              path: "/ai-campus/projects",
            },
            {
              text: "Request Information",
              path: "https://forms.gle/kgcSGVP9fFb4u5f49",
            },
            {
              text: "Current Team",
              path: "/ai-campus/current-team",
            },
          ],
        },
        {
          path: "/partners",
          text: "Partners",
          routes: [
            {
              text: "HBCUs",
              path: "/partners/hbcus",
            },
            {
              text: "Arkansas Partners",
              path: "/partners/arkansas-partners",
            },
            {
              text: "No-Boundary Thinking Partners",
              path: "/partners/nbt-partners",
            },
            {
              text: "EPSCoR Partners",
              path: "/partners/epscor-partners",
            },
          ],
        },
        {
          text: "Organization",
          path: "/organization",
        },
        {
          text: "News & Seminars",
          path: "/news",
        },
      ],
    };
  },
  setup() {
    const open = ref(false);

    return {
      open,
    };
  },
  methods: {
    logout() {
      post("/logout");
      this.$store.commit("logout");
    },
  },
  computed: {
    isLoggedIn() {
      console.log(this.$route);
      return this.$store.state.isLoggedIn;
    },
  },
  watch: {
    $route(route) {
      this.selectedPath = route.fullPath;
    },
  },
};
</script>
