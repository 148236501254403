<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <main>
    <div>
      <!-- Hero card -->
      <div class="relative md:mt-3">
        <div class="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div class="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
            <div class="absolute inset-0">
              <img
                class="h-full w-full object-cover"
                src="../assets/img/ai-campus-group.webp"
                alt="AI-Campus Group At Arkansas Capitol"
              />
              <div class="absolute inset-0 bg-blue-500 mix-blend-multiply" />
            </div>
            <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1
                class="
                  text-center text-4xl
                  font-extrabold
                  tracking-tight
                  sm:text-5xl
                  lg:text-6xl
                "
              >
                <span class="block text-white">Welcome to</span>
                <span class="block text-white">AI-Campus</span>
              </h1>
              <p
                class="
                  mt-6
                  max-w-lg
                  mx-auto
                  text-center text-xl text-white
                  sm:max-w-3xl
                "
              >
                AI-Campus is unique and free training for undergraduate through
                junior faculty how to apply AI techniques to their disciplines.
                Domain experts across multiple disciplines lead teams as they
                complete research projects.
              </p>
              <blockquote
                class="
                  mt-6
                  max-w-lg
                  mx-auto
                  text-center text-xl text-white
                  sm:max-w-3xl
                "
              >
                "AI will change our life, but AI will never replace our life."
              </blockquote>
              <div
                class="
                  th3
                  mt-10
                  max-w-sm
                  mx-auto
                  sm:max-w-none sm:flex sm:justify-center
                "
              >
                <div class="space-y-4 sm:space-y-0 sm:mx-auto">
                  <router-link
                    to="/ai-campus/info"
                    class="
                      flex
                      items-center
                      justify-center
                      px-4
                      py-3
                      border border-transparent
                      text-base
                      font-medium
                      rounded-md
                      shadow-sm
                      text-indigo-700
                      bg-white
                      hover:bg-indigo-50
                      sm:px-8
                    "
                  >
                    Learn More
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Stats />
      <!-- Map Section -->
      <div class="">
        <img class="w-3/5 h-3/5 mx-auto mb-8" src="/map.png" alt="" />
      </div>
    </div>
  </main>
</template>

<script>
import Stats from "@/components/Stats.vue";

export default {
  name: "Landing",
  components: { Stats },
  setup() {},
};
</script>
