import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import { post } from "../helper";
import store from "../store/index";

const aiWorkshopPlanningCommittee = [
  { name: "Xiuzhen Huang (Chair)", institution: "Arkansas State University" },
  { name: "Kenrick Mock", institution: "University of Alaska Anchorage" },
  { name: "Hyunju Connor", institution: "University of Alaska Fairbanks" },
  { name: "Erin Whitney", institution: "University of Alaska Fairbanks" },
  { name: "Jeff Carver", institution: "University of Alabama" },
  { name: "Jiaqi (Jackey) Gong", institution: "University of Alabama" },
  { name: "Jinhui Wang", institution: "University of South Alabama" },
  { name: "Na Gong", institution: "University of South Alabama" },
  {
    name: "Jennifer Fowler",
    institution: "Arkansas Economic Development Commission",
  },
  { name: "Karl Walker", institution: "University of Arkansas at Pine Bluff" },
  { name: "James Foster", institution: "University of Idaho" },
  { name: "Xiaogang (Marshall) Ma", institution: "University of Idaho" },
  { name: "Hana Khamfroush", institution: "University of Kentucky" },
  {
    name: "Tanya Lueder",
    institution: "Louisiana State University Alexandria",
  },
  { name: "Bindu Nanduri", institution: "Mississippi State University" },
  { name: "Andy Perkins", institution: "Mississippi State University" },
  { name: "Saeed Salem", institution: "North Dakota State University" },
  { name: "Jeremy Straub", institution: "North Dakota State University" },
  { name: "Alireza Tavakkoli", institution: "University of Nevada at Reno" },
  { name: "Joan Peckham", institution: "University of Rhode Island" },
  { name: "Bryan Dewsbury", institution: "University of Rhode Island" },
  { name: "Amit Sheth", institution: "University of South Carolina" },
  { name: "Forest Agostinelli", institution: "University of South Carolina" },
  { name: "Stephen Gent", institution: "South Dakota State University" },
];

const nbtMembers = [
  {
    name: "Xiuzhen Huang",
    institution: "Arkansas State University",
    department: "Computer Science",
    title: "Professor",
    state: "AR",
  },
  {
    name: "Carole Cramer",
    institution: "Arkansas State University",
    department: "Biology",
    title: "Professor",
    state: "AR",
  },
  {
    name: "Jason Causey",
    institution: "Arkansas State University",
    department: "Bioinformatics",
    title: "Assistant Professor",
    state: "AR",
  },
  {
    name: "Jake Qualls",
    institution: "Arkansas State University",
    department: "Bioinformatics",
    title: "Assistant Professor",
    state: "AR",
  },
  {
    name: "Ainong Shi",
    institution: "University of Arkansas",
    department: "Horticulture",
    title: "Associate Professor",
    state: "AR",
  },
  {
    name: "Steven F. Jennings",
    institution: "University of Arkansas at Little Rock",
    department: "Information Science and Bioinformatics",
    title: "Professor Emeritus",
    state: "AR",
  },
  {
    name: "Karl Walker",
    institution: "University of Arkansas at Pine Bluff",
    department: "Mathematics and Computer Science",
    title: "Professor",
    state: "AR",
  },
  {
    name: "Cody Ashby",
    institution: "Winthrop P. Rockefeller Institute",
    department: "Bioinformatics",
    title: "Assistant Professor",
    state: "AR",
  },
  {
    name: "Gail McClure",
    institution: "",
    department: "",
    title: "",
    state: "AR",
  },
  {
    name: "Uwe Hilgert",
    institution: "University of Arizona",
    department: "biology",
    title: "Associate Research Professor",
    state: "AZ",
  },
  {
    name: "Zhenglu Li",
    institution: "UC berkley",
    department: "Physics",
    title: "professor",
    state: "CA",
  },
  {
    name: "Liming Cai",
    institution: "University of Georgia",
    department: "Computer Science",
    title: "Professor",
    state: "GA",
  },
  {
    name: "James Foster",
    institution: "University of Idaho",
    department: "Biological Sciences",
    title: "Professor",
    state: "ID",
  },
  {
    name: "Hongmei Jiang",
    institution: "Northwestern",
    department: "Statistics",
    title: "Associate Professor",
    state: "IL",
  },
  {
    name: "Mark Alber",
    institution: "Notre Dame",
    department: "Applied and Computational Mathematics",
    title: "Professor",
    state: "IN",
  },
  {
    name: "Clare Bates Congdon",
    institution: "University of Southern Maine",
    department: "computer science",
    title: "associate professor",
    state: "ME",
  },
  {
    name: "Yuanfang Guan",
    institution: "University of Michigan",
    department: "Computational Medicine and Bioinformatics",
    title: "associate professor",
    state: "MI",
  },
  {
    name: "David Beer",
    institution: "University of Michigan",
    department: "Michigan Medicine",
    title: "Professor of Surgery and Radiation Oncology",
    state: "MI",
  },
  {
    name: "Hongyang Li",
    institution: "University of Michigan",
    department: "Computational medicine and bioinformatics",
    title: "Student",
    state: "MI",
  },
  {
    name: "Weihua Guan",
    institution: "University of Minnesota",
    department: "Biostatistics",
    title: "Associate Professor",
    state: "MN",
  },
  {
    name: "Shuzhong Zhang",
    institution: "University of Minnesota",
    department: "Industrial and Systems Engineering",
    title: "Professor and Department Head",
    state: "MN",
  },
  {
    name: "Donald Wunsch",
    institution: "Missouri S&T",
    department: "Electrical and Computer Engineering",
    title: "Professor",
    state: "MO",
  },
  {
    name: "Pengyin Chen",
    institution: "University of Missouri",
    department: "Plant Sciences",
    title: "Professor",
    state: "MO",
  },
  {
    name: "Bindu Nanduri",
    institution: "Mississpi State University",
    department: "basic sciences",
    title: "assistant professor",
    state: "MS",
  },
  {
    name: "Andy Perkins",
    institution: "Mississpi State University",
    department: "Computer Science and Engineering",
    title: "Associate Professor",
    state: "MS",
  },
  {
    name: "Saeed Salem",
    institution: "North Dakota state",
    department: "computer sciences",
    title: "associate professor",
    state: "ND",
  },
  {
    name: "Scott Williams",
    institution: "Case Western Reserve University",
    department: "Epidemiology and Biostatistics",
    title: "Professor",
    state: "OH",
  },
  {
    name: "Yan Zhang",
    institution: "Ohio State College of Medicine",
    department: "Biomedical Informatics",
    title: "Assistant Professor",
    state: "OH",
  },
  {
    name: "David A Liberles",
    institution: "Temple University",
    department: "Biology",
    title: "Associate Professor",
    state: "PA",
  },
  {
    name: "Casey Greene",
    institution: "University of Pennsylvania",
    department: "Pharmacology",
    title: "Associate Professor",
    state: "PA",
  },
  {
    name: "Jason H Moore",
    institution: "University of Pennsylvania",
    department: "Biostatistics and Epidemiology",
    title: "Professor",
    state: "PA",
  },
  {
    name: "John Holmes",
    institution: "University of Pennsylvania",
    department: "Biostatistics and Epidemiology",
    title: "Assosciate Professor",
    state: "PA",
  },
  {
    name: "Joan Peckham",
    institution: "University of Rhode Island",
    department: "Computer Science and Statistics",
    title: "Professor",
    state: "RI",
  },
  {
    name: "Bryan Dewsbury",
    institution: "University of Rhode Island",
    department: "biological sciences",
    title: "assistant professor",
    state: "RI",
  },
  {
    name: "Jennifer Specker",
    institution: "University of Rhode Island",
    department: "Coastal Institute",
    title: "",
    state: "RI",
  },
  {
    name: "Barry Bruce",
    institution: "University of Tennessee (Knoxville)",
    department: "biochemistry and cellular and molecular biology",
    title: "Professor",
    state: "TN",
  },
  {
    name: "Daniel L Johnson",
    institution: "University of Tennessee",
    department: "Health Science Center",
    title: "mBio Center Director",
    state: "TN",
  },
  {
    name: "Alison Buchan",
    institution: "University of Tennessee (Knoxville)",
    department: "Microbiology",
    title: "Professor",
    state: "TN",
  },
  {
    name: "Rick McMullen",
    institution: "Texas A&M",
    department: "High Performance Research Computing",
    title: "",
    state: "TX",
  },
  {
    name: "Zhongming Zhao",
    institution: "University of Texas Health Science Center at Houston",
    department: "Biomedical Informatics",
    title: "Chair Professor for Precision Health",
    state: "TX",
  },
  {
    name: "Aidong Zhang",
    institution: "University of Virginia",
    department: "Computer Science",
    title: "William Wulf Faculty Fellow and Professor",
    state: "VA",
  },
  {
    name: "Phil Bourne",
    institution: "University of Virginia",
    department: "Biomedical Engineering",
    title: [
      "Stephenson Chair of Data Science",
      "Director of the Data Science Institute and Professor of Biomedical Engineering",
    ],
    multipleTitles: true,
    state: "VA",
  },
  {
    name: "Donghai Xiong",
    institution: "Medical College of Wisconsin",
    department: "Pharmacology and Toxicology",
    title: "assistant professor",
    state: "WI",
  },
];

const onSiteMentors = [
  {
    name: "Karl Walker",
    title: "Chair, Assoc. Prof.",
    institution: "Univ. of Arkansas at Pine Bluff",
    expertise: "Bioinf., AI, database",
  },
  {
    name: "Danny Czejdo",
    title: "Prof. of Science",
    institution: "Fayetteville State University",
    expertise: "knowledge sys., CS edu.",
  },
  {
    name: "Samar Swaid",
    title: "Chair, Assoc. Prof.",
    institution: "Philander Smith College",
    expertise: "CS, biol. science",
  },
  {
    name: "Timothy Oladunni",
    title: "Asst. Prof.",
    institution: "Univ. of the District of Columbia",
    expertise: "AI, ML, Deep Learning",
  },
  {
    name: "Letu Qingge",
    title: "Asst. Prof.",
    institution: "North Carolina A&T State Univ.",
    expertise: "Bioinf., comp. biol., data sci.",
  },
  {
    name: "Sarah Buxbaum",
    title: "Assoc. Prof.",
    institution: "Florida A&M University",
    expertise: "Data sci., biostats, pub. health",
  },
  {
    name: "Babu Baniya",
    title: "Asst. Prof.",
    institution: "Grambling State University",
    expertise: "ML, Bioinf., Big Data",
  },
  {
    name: "Denise Ferebee",
    title: "Asst. Prof., Director",
    institution: "LeMoyne-Owen College",
    expertise: "CS, Cybersecurity",
  },
  {
    name: "Noushin Ghaffari",
    title: "Asst. Prof.",
    institution: "Prairie View A&M University",
    expertise: "Bioinformatics, AI",
  },
  {
    name: "Sridhar Malkaram",
    title: "Assoc. Prof.",
    institution: "West Virginia State University",
    expertise: "Bioinformatics",
  },
  {
    name: "Michelle Foster",
    title: "Chair, Coordinator",
    institution: "Alabama State University",
    expertise: "Applied math, CS",
  },
  {
    name: "Michael Blanchett",
    title: "Program Director",
    institution: "Shorter College",
    expertise: "STEM education",
  },
  {
    name: "Ayishih Bellew",
    title: "Professor",
    institution: "University of the Virgin Islands",
    expertise: "Data science, analytics, CS",
  },
  {
    name: "Zephyrinus C. Okonkwo",
    title: "Professor",
    institution: "Albany State University",
    expertise: "CS edu., math, Cryptography",
  },
  {
    name: "Suzan Anwar",
    title: "Asst. Prof.",
    institution: "Philander Smith College",
    expertise: "CS, Computer Vision, AI",
  },
  {
    name: "Hongmei Chi",
    title: "Professor",
    institution: "Florida A&M University",
    expertise: "Data sci., Cybersec., Bioinf.",
  },
  {
    name: "Linwei Niu",
    title: "Asst. Prof",
    institution: "Howard University",
    expertise: "Embedded Systems, Sustainable Comp",
  },
  {
    name: "Lei Huang",
    title: "Assoc. Prof.",
    institution: "Prairie View A&M University",
    expertise: "Cloud Computing, Big Data, HPC",
  },
  {
    name: "Demetrius Gilbert",
    title: "Assoc. Dean",
    institution: "Shorter College",
    expertise: "Education, Development",
  },
  {
    name: "Marc Boumedine",
    title: "Assoc. Prof.",
    institution: "University of the Virgin Islands",
    expertise: "Fuzzy Logic, Data Mining",
  },
  {
    name: "Joanne Luciano",
    title: "Professor",
    institution: "University of the Virgin Islands",
    expertise: "Bioinf., Knowledge repr. Data Sci",
  },
  {
    name: "Robert Owor",
    title: "Professor",
    institution: "Albany State University",
    expertise: "AI, HCI, Cybersec",
  },
  {
    name: "Ping Zhang",
    title: "Chair, Professor",
    institution: "Alcorn State University",
    expertise: "Computer Vision, AI/ML",
  },
  {
    name: "Raphael Isokpehi",
    title: "Professor",
    institution: "Jackson State University",
    expertise: "Bioinf., NLP,",
  },
  {
    name: "Sanjay Batra",
    title: "Assoc. Professor",
    institution: "Southern University-Baton Rouge",
    expertise: "Biochem, Molecular Biology",
  },
  {
    name: "Nikunja Swain",
    title: "Chair, Professor",
    institution: "South Carolina State University",
    expertise: "Cybersecurity, CS edu.",
  },
  {
    name: "Naima Naheed",
    title: "Assoc. Professor",
    institution: "Benedict College",
    expertise: "Digital Image Processing, Cybersec, Applied Math",
  },
  {
    name: "Mian Hossain",
    title: "Professor",
    institution: "Morgan State University",
    expertise: "Health & Policy, Statistics",
  },
];

const routes = [
  {
    path: "/users/:id",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
  },
  {
    path: "/directory",
    name: "Directory",
    component: () =>
      import(/* webpackChunkName: "directory" */ "../views/Directory.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // {
  //   path: "/news",
  //   name: "News",
  //   component: () => import(/* webpackChunkName: "news" */ "../views/News.vue"),
  // },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/organization",
    name: "Organization",
    component: () =>
      import(
        /* webpackChunkName: "organization" */ "../views/Organization.vue"
      ),
  },
  {
    path: "/ai-campus/current-team",
    name: "Current Tam",
    component: () =>
      import(/* webpackChunkName: "current-team" */ "../views/About.vue"),
  },
  {
    path: "/news",
    name: "Timeline",
    component: () =>
      import(/* webpackChunkName: "timeline" */ "../views/Timeline.vue"),
  },
  {
    path: "/projects",
    name: "Projects",
    component: () =>
      import(/* webpackChunkName: "projects" */ "../views/Projects.vue"),
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: () =>
  //     import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () =>
  //     import(/* webpackChunkName: "register" */ "../views/Register.vue"),
  // },
  {
    path: "/ai-campus/info",
    name: "AI Campus Info",
    component: () =>
      import(/* webpackChunkName: "aic-info" */ "../views/AICampusInfo.vue"),
  },
  {
    path: "/ai-campus/projects",
    name: "AI Campus Projects",
    component: () =>
      import(
        /* webpackChunkName: "aic-projects" */ "../views/AICampusProjects.vue"
      ),
  },
  {
    path: "/partners/:partnerName",
    name: "Partners",
    component: () =>
      import(
        /* webpackChunkName: "partners" */ "../components/PartnerPage.vue"
      ),
    props(route) {
      const partners = {
        hbcus: {
          headerText: `On-site Mentors at Participating HBCUs`,
          partnerName: "Historically Black Colleges and Universities",
          partnerBio:
            "AI-Campus has partnered with 28 on-site mentors at 21 Historically Black Colleges and Universities.",
          partners: onSiteMentors,
        },
        "arkansas-partners": {
          headerText: "",
          partnerName: "Arkansas Partners",
          partnerBio:
            "AI-Campus has partnered with 11 universities and institutions in Arkansas.",
          partners: [
            { name: "Arkansas State University" },
            { name: "University of Arkansas for Medical Sciences" },
            { name: "University of Arkansas Little Rock" },
            { name: "University of Arkansas Pine Bluff" },
            { name: "University of Arkansas - Fayetteville" },
            { name: "Philander Smith College" },
            { name: "Arkansas Tech University" },
            { name: "Shorter College" },
            { name: "National Center for Toxicological Research " },
            { name: "Arkansas Children’s Hospital" },
            { name: "University of Central Arkansas" },
          ],
        },
        "nbt-partners": {
          headerText: "",
          partnerName: "No-Boundary Thinking Partners",
          partnerBio: "",
          partners: nbtMembers,
        },
        "epscor-partners": {
          headerText: "",
          partnerName: "EPSCoR Partners",
          partnerBio: "AI-Campus has Partners from 12 EPSCoR States",
          partners: aiWorkshopPlanningCommittee,
        },

        // "advanced-data-science-learning": "Advanced Data Science & Learning",
        // "medical-sciences-public-health": "Medical Sciences and Public Health",
        // "algorithms-computational-methodology":
        //   "Algorithms & Computational Methodology",
      };

      const partner = partners[route.params.partnerName];
      return {
        // TODO: Grab this info from db
        ...partner,
      };
    },
  },
  // {
  //   path: "/news/:slug",
  //   name: "NewsArticle",
  //   component() {
  //     return import(
  //       /* webpackChunkName: "newsArticle" */ "../components/NewsArticle.vue"
  //     );
  //   },
  // },
  // {
  //   path: "/ai-campus",
  //   name: "AI_Campus",
  //   component: () =>
  //     import(/* webpackChunkName: "ai-campus" */ "../views/AI_CampusPage.vue"),
  // },
  {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(
        /* webpackChunkName: "pageNotFound" */ "../views/PageNotFound.vue"
      ),
  },
  {
    path: "/connect4",
    name: "Connect 4 Demo",
    component: () =>
      import(
        /* webpackChunkName: "connect4Demo" */ "../views/connect4Demo.vue"
      ),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const res = await post("/refresh");
  if (res.status === 401) {
    store.commit("logout");
    return next();
  }

  next();
});

export default router;
