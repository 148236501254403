<template>
  <div class="home">
    <!-- <link rel="stylesheet" href="style.css"> -->
    <Landing />
  </div>
</template>

<script>
// @ is an alias to /src
import Landing from "@/views/Landing.vue";

export default {
  name: "Home",
  components: {
    Landing,
  },
};
</script>
