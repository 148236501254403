import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    isLoggedIn: false,
    user: {},
    API_URL: process.env.VUE_APP_BASE_URL,
  },
  mutations: {
    setLoggedIn(state, payload) {
      this.state.isLoggedIn = payload;
    },
    setUser(state, payload) {
      this.state.user = payload;
    },
    logout() {
      this.state.isLoggedIn = false;
      this.state.user = {};
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
