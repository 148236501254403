<template>
  <head>
    <link rel="stylesheet" href="/style.css" />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/site.webmanifest" />
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
    <meta name="msapplication-TileColor" content="#da532c" />
    <meta name="theme-color" content="#ffffff" />
    <title>AI Campus</title>
  </head>
  <!-- Nav goes here -->
  <body class="flex flex-col h-screen">
    <Nav class="z-50"></Nav>
    <router-view class="flex-1 z-0" />
    <Footer></Footer>
  </body>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.newsPost {
  outline: 0px solid transparent;
  overflow-y: auto;
  height: 350px;

  blockquote {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 3rem;
    position: relative;

    &::before {
      content: " ";
      width: 2px;
      background: rgb(230, 185, 185);
      position: absolute;
      left: 0;
      top: 1.5rem;
      bottom: 1.5rem;
    }
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  li {
    margin-left: 20px;
  }

  a {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }

  h1 {
    font-size: 3.75rem;
    line-height: 1;
    font-weight: bold;
  }

  h2 {
    font-size: 3rem;
    line-height: 1;
  }

  h3 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  h4 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  h5 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  h6 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  p {
    height: 3rem;
  }
}
</style>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import { useHead } from "@vueuse/head";

export default {
  components: {
    Nav,
    Footer,
  },
  setup() {
    useHead({
      title: "AI Campus",
      meta: [
        {
          name: `description`,
          content:
            "CNBT's mission is to provide a cross disciplinary research group that is committed to no-boundary thinking.",
        },
        { property: "og:title", content: "AI Campus" },
        {
          property: "og:description",
          content:
            "CNBT's mission is to provide a cross disciplinary research group that is committed to no-boundary thinking.",
        },
        {
          property: "og:image",
          content: "https://cnbt-astate.com/img/ai-campus-group.7a3bd08a.webp",
        },
        { property: "og:url", content: "https://cnbt-astate.com" },

        {
          property: "twitter:title",
          content: "AI Campus",
        },
        {
          property: "twitter:description",
          content:
            "CNBT's mission is to provide a cross disciplinary research group that is committed to no-boundary thinking.",
        },
        {
          property: "twitter:image",
          content: "https://cnbt-astate.com/img/ai-campus-group.7a3bd08a.webp",
        },
        { property: "twitter:url", content: "https://cnbt-astate.com" },
      ],
    });
  },
};
</script>
