<template>
  <div class="bg-gray-100 pt-12 sm:pt-16">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="max-w-4xl mx-auto text-center">
        <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          AI-Campus has partners in
          <span class="text-blue-600">14</span> states,
          <span class="text-blue-600">Washington D.C.</span>, and the
          <span class="text-blue-600">Virgin Islands</span>.
        </h2>
        <p class="mt-3 text-xl text-gray-500 sm:mt-4">
          We continue to expand AI-Campus to help students and mentors grow
          their network and build profesional relationships across the nation.
        </p>
        <a
          href="https://forms.gle/sFqpVjBLXA1HYwqS7"
          class="
            bg-sky-500
            text-white
            rounded-3xl
            mt-5
            text-3xl
            px-4
            py-2
            cursor-pointer
            inline-block
          "
        >
          Join Us!
        </a>
      </div>
    </div>

    <!-- Stats Section -->
    <div class="mt-10 pb-12 bg-white sm:pb-16">
      <div class="relative">
        <div class="absolute inset-0 h-1/2 bg-gray-100" />
        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="max-w-4xl mx-auto">
            <dl class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-2">
              <div
                class="
                  flex flex-col
                  border-b border-gray-100
                  p-6
                  text-center
                  sm:border-0 sm:border-r
                "
              >
                <dt
                  class="
                    order-2
                    mt-2
                    text-lg
                    leading-6
                    font-medium
                    text-gray-500
                  "
                >
                  On-site mentors
                </dt>
                <dd class="order-1 text-5xl font-extrabold text-blue-600">
                  34
                </dd>
              </div>
              <div
                class="
                  flex flex-col
                  border-t border-b border-gray-100
                  p-6
                  text-center
                  sm:border-0 sm:border-l sm:border-r
                "
              >
                <dt
                  class="
                    order-2
                    mt-2
                    text-lg
                    leading-6
                    font-medium
                    text-gray-500
                  "
                >
                  Partner institutions
                </dt>
                <dd class="order-1 text-5xl font-extrabold text-blue-600">
                  30
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>
