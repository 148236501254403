"use strict";

// Always sends data as application/json
function post(
  path,
  { headers = {}, body = "", query = {} } = {} // Allow empty config
) {
  // 1. Make the URL
  const url = new URL(path, process.env.VUE_APP_BASE_URL);

  // 2. Add any search params
  for (const key in query) {
    url.searchParams.append(key, query[key]);
  }

  let payload = {
    method: "POST",
  };

  if (body) {
    // 3. Add JSON content type
    headers["Content-Type"] = "application/json";

    // 4. Stringify the body and create payload
    payload["body"] = JSON.stringify(body);
  }

  console.log("Body");
  console.log(body);
  payload = { ...payload, headers, credentials: "include" };
  console.log("Payload");
  console.log(payload);
  // 5. Fetch
  return fetch(url, payload);
}

function postMultipart(
  path,
  { headers = {}, body = "", query = {}, files = [] } = {} // Allow empty config
) {
  // 1. Make the URL
  const url = new URL(path, process.env.VUE_APP_BASE_URL);

  // 2. Add any search params
  for (const key in query) {
    url.searchParams.append(key, query[key]);
  }

  let payload = {
    method: "POST",
  };

  const formData = new FormData();

  if (body) {
    for (const prop in body) {
      formData.append(prop, body[prop]);
    }
  }

  // Add files
  for (const file of files) {
    formData.append(file.name, file.file);
  }

  // Don't set the Content-Type for multipart/form-data; the browser will handle it
  // 3. Add the form payload
  payload["body"] = formData;

  console.log("Body");
  console.log(body);
  payload = { ...payload, headers, credentials: "include" };
  console.log("Payload");
  console.log(payload);
  // 5. Fetch
  return fetch(url, payload);
}

// Always sends data as application/json
function get(
  path,
  { headers = {}, query = {} } = {} // Allow empty config
) {
  console.log(`GETTING: ${path}`);
  // 1. Make the URL
  const url = new URL(path, process.env.VUE_APP_BASE_URL);

  // 2. Add any search params
  for (const key in query) {
    url.searchParams.append(key, query[key]);
  }

  let payload = {
    method: "GET",
  };

  headers = {
    Accept: "application/json",
    ...headers,
  };

  payload = { ...payload, headers, credentials: "include" };
  console.log(payload);
  // 5. Fetch
  return fetch(url, payload);
}

function formatDate(
  timeSinceEpoch,
  { dateOptions = { year: "numeric", month: "long", day: "numeric" } } = {}
) {
  return new Date(timeSinceEpoch).toLocaleDateString("en-US", dateOptions);
}

function getImgUrl(img) {
  const images = require.context("./assets/", true);
  try {
    return images("./" + img);
  } catch {
    return images("./img/missing.png");
  }
}

export { post, postMultipart, get, formatDate, getImgUrl };

// const apiKey = "AIzaSyCI0ZV_yyRSqrXnCO_dfC3jAIQDnLr8bV4";
// let places = [
//   "Philander Smith College",
//   "Shorter College",
//   "University of the Virgin Islands",
//   "Albany State University",
//   "Florida A&M University",
//   "Prairie View A&M University",
//   "Alabama State University",
//   "Alcorn State University",
//   "Arkansas Children’s Hospital",
//   "Arkansas State University",
//   "Arkansas Tech University",
//   "Benedict College",
//   "Fayetteville State University",
//   "Grambling State University",
//   "Howard University",
//   "Jackson State University",
//   "LeMoyne-Owen College",
//   "Morgan State University",
//   "National Center for Toxicological Research",
//   "North Carolina A&T State Univ.",
//   "South Carolina State University",
//   "Southern University-Baton Rouge",
//   "Univ. of Arkansas at Pine Bluff",
//   "Univ. of the District of Columbia",
//   "University of Arkansas - Fayetteville",
//   "University of Arkansas for Medical Sciences",
//   "University of Arkansas Little Rock",
//   "University of Arkansas Pine Bluff",
//   "University of Central Arkansas",
//   "West Virginia State University",
// ];

// const geocodes = [
//   {
//     name: "Philander Smith College",
//     position: {
//       lat: 34.7371492,
//       lng: -92.28296929999999,
//     },
//   },
//   {
//     name: "Shorter College",
//     position: {
//       lat: 34.7592275,
//       lng: -92.2604236,
//     },
//   },
//   {
//     name: "University of the Virgin Islands",
//     position: {
//       lat: 18.3432344,
//       lng: -64.9728797,
//     },
//   },
//   {
//     name: "Albany State University",
//     position: {
//       lat: 31.5690557,
//       lng: -84.1403888,
//     },
//   },
//   {
//     name: "Florida A&M University",
//     position: {
//       lat: 30.3848991,
//       lng: -84.2291968,
//     },
//   },
//   {
//     name: "Prairie View A&M University",
//     position: {
//       lat: 30.0939175,
//       lng: -95.98989639999999,
//     },
//   },
//   {
//     name: "Alabama State University",
//     position: {
//       lat: 32.3636575,
//       lng: -86.2940174,
//     },
//   },
//   {
//     name: "Alcorn State University",
//     position: {
//       lat: 31.8763897,
//       lng: -91.1425105,
//     },
//   },
//   {
//     name: "Arkansas Children’s Hospital",
//     position: {
//       lat: 34.7408049,
//       lng: -92.2925603,
//     },
//   },
//   {
//     name: "Arkansas State University",
//     position: {
//       lat: 35.8430861,
//       lng: -90.67485889999999,
//     },
//   },
//   {
//     name: "Arkansas Tech University",
//     position: {
//       lat: 35.29378880000001,
//       lng: -93.1361472,
//     },
//   },
//   {
//     name: "Benedict College",
//     position: {
//       lat: 34.01302,
//       lng: -81.0195629,
//     },
//   },
//   {
//     name: "Fayetteville State University",
//     position: {
//       lat: 35.0742206,
//       lng: -78.8924896,
//     },
//   },
//   {
//     name: "Grambling State University",
//     position: {
//       lat: 32.5206702,
//       lng: -92.7189283,
//     },
//   },
//   {
//     name: "Howard University",
//     position: {
//       lat: 38.9226843,
//       lng: -77.0194377,
//     },
//   },
//   {
//     name: "Jackson State University",
//     position: {
//       lat: 32.2968851,
//       lng: -90.2064252,
//     },
//   },
//   {
//     name: "LeMoyne-Owen College",
//     position: {
//       lat: 35.1180562,
//       lng: -90.0344386,
//     },
//   },
//   {
//     name: "Morgan State University",
//     position: {
//       lat: 39.3437959,
//       lng: -76.5843847,
//     },
//   },
//   {
//     name: "National Center for Toxicological Research",
//     position: {
//       lat: 34.3661992,
//       lng: -92.1125936,
//     },
//   },
//   {
//     name: "North Carolina A&T State Univ.",
//     position: {
//       lat: 35.7595731,
//       lng: -79.01929969999999,
//     },
//   },
//   {
//     name: "South Carolina State University",
//     position: {
//       lat: 33.4984666,
//       lng: -80.8498116,
//     },
//   },
//   {
//     name: "Southern University-Baton Rouge",
//     position: {
//       lat: 30.5265222,
//       lng: -91.1929407,
//     },
//   },
//   {
//     name: "Univ. of Arkansas at Pine Bluff",
//     position: {
//       lat: 34.2468716,
//       lng: -92.0213392,
//     },
//   },
//   {
//     name: "Univ. of the District of Columbia",
//     position: {
//       lat: 38.9446253,
//       lng: -77.06563009999999,
//     },
//   },
//   {
//     name: "University of Arkansas - Fayetteville",
//     position: {
//       lat: 36.0686895,
//       lng: -94.1748471,
//     },
//   },
//   {
//     name: "University of Arkansas for Medical Sciences",
//     position: {
//       lat: 34.7493154,
//       lng: -92.320596,
//     },
//   },
//   {
//     name: "University of Arkansas Little Rock",
//     position: {
//       lat: 34.7252717,
//       lng: -92.3378705,
//     },
//   },
//   {
//     name: "University of Arkansas Pine Bluff",
//     position: {
//       lat: 34.2468716,
//       lng: -92.0213392,
//     },
//   },
//   {
//     name: "University of Central Arkansas",
//     position: {
//       lat: 35.0780939,
//       lng: -92.4578916,
//     },
//   },
//   {
//     name: "West Virginia State University",
//     position: {
//       lat: 38.3789586,
//       lng: -81.7665882,
//     },
//   },
// ];

// for (const place of places) {
//   document.querySelector('body > div > div.col-left.col > div.controls > div > form > fieldset:nth-child(10) > div.form-group > button').click()
//   document.querySelectorAll('[ng-model="marker.coordinates"]')[document.querySelectorAll('[ng-model="marker.coordinates"]').length-1].value = place;
// }
// const geocodes = [];
//  async function gc (place) {
//     const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${place.replace(/\s/g, "+")}&key=AIzaSyCI0ZV_yyRSqrXnCO_dfC3jAIQDnLr8bV4`)
//     const data = await res.json();
//     console.log(data);
//     try {
//       console.log({name: place, position: data.results[0].geometry.location})
//     } catch {
//       console.log("Couldn't get: ", place)
//     }

//   }

// async function getCodes () {
//   for (const place of places) {
//     await gc(place);
//   }
//   console.log(geocodes)
// }
// getCodes();

// import { Loader } from "@googlemaps/js-api-loader";

// export default {
//   data () {
//     return {
//       google: undefined,
//       map: undefined,
//     };
//   },
//   async mounted() {
//     console.log(geocodes);
//     const loader = new Loader({
//       apiKey,
//       version: "weekly",
//       libraries: ["places"],
//     });

//     const mapOptions = {
//       center: {lat: 35.0077519, lng: -97.092877},
//       zoom: 4,
//     };

//     this.google = await loader.load()
//     this.map = await new this.google.maps.Map(document.getElementById("map"), mapOptions);
//     for (const geocode of geocodes) {
//       new this.google.maps.Marker({
//         position: geocode.position,
//         map: this.map,
//         title: geocode.place,
//       });
//     }
//   },
// };
